import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid, NumberInput } from 'components/lib';

import Style from './utility-requirements.module.scss';

import { Alert, Divider, Typography } from 'antd';

const { Text } = Typography;

export function UpdateUtilityReq({ utility, accountId, values }) {
    const context = useContext(ViewContext);
    
    const formObj = {
        date: undefined,
        max_injection: undefined,
        max_withdrawal: undefined,
        interruptible_requirements: [],
        cc_requirement: undefined
    };

    const prepareFormObj = () => {
        return {...formObj, ...values};
    }

    const [loading, setLoading] = useState(false);
    const [formState, setFormState] = useState(prepareFormObj());
    const [requirementTotal, setRequirementTotal] = useState(0);

    useEffect(() => {

        const interruptibleReqs = formState.interruptible_requirements;
        const interruptibleReqTotal = interruptibleReqs.reduce((total, obj) => obj.requirement ? obj.requirement + total : total , 0);
        const ccReqTotal = formState.cc_requirement || 0;
    
        setRequirementTotal(interruptibleReqTotal + ccReqTotal);

    }, [formState]);

    const setValue = (id, value) => {
        if(id in formState) {
            setFormState({...formState, [id]: value ? parseInt(value) : 0 });
        }
    }

    const setInterruptibleReqValue = (id, value) => {
        const interruptibleReqs = formState.interruptible_requirements; 
        const currentIndex = interruptibleReqs.findIndex(obj => obj.interruptible_id === id);
        const newReqData = currentIndex > -1 ? 
            interruptibleReqs.map(obj => obj.interruptible_id === id ? { ...obj, requirement: value } : obj) :
            [...formState.interruptible_requirements, { interruptible_id: id, requirement: value }]

        setFormState({...formState, interruptible_requirements: newReqData });
    }

    const isValid = () => {
        const keys = Object.keys(formState);
        const missingVals = keys.filter(key => formState[key] === undefined);

        return !missingVals.length;
    }

    const submit = async () => {
        setLoading(true);

        try {
            const res = await Axios.post('/api/company-utility-requirements/data-entry', { 
                ...formState,
                requirement: requirementTotal,
                date: formState.date.format('YYYY-MM-DD'),
                account_id: accountId,
                utility_id: utility.id
            });

            if (res.status === 200) {
                setLoading(false);
                context.modal.hide(false, res.data.data);
            }
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }

    const renderInterruptibleReqForm = () => {
        if (!utility.interruptibles) return null;

        return (
            <>
                <Divider orientation="left">
                    Interruptibles
                </Divider>

            {utility.interruptibles.length > 0 &&
                utility.interruptibles.map(intObj => {
                    const reqObj = formState.interruptible_requirements ?
                        formState.interruptible_requirements.find(obj => obj.interruptible_id === intObj.id) :
                        undefined;

                    return (
                            <div>
                                <NumberInput 
                                    label={`${intObj.name} volume`}
                                    required
                                    placeholder="0"
                                    name={intObj.id}
                                    id={intObj.id}
                                    onChange={setInterruptibleReqValue}
                                    value={reqObj?.requirement}
                                />
                            </div>
                    )
                })
            }

            {!utility.interruptibles.length &&
                <Text>No interruptibles on utility.</Text>
            }
            </>
            
        );

    }

    const renderChoiceCustomerReqForm = () => {
        return (
            <>
                <Divider />
                <div>
                    <NumberInput 
                        label="Choice Customer Volume"
                        required
                        placeholder="0"
                        name="cc_requirement"
                        id="cc_requirement"
                        onChange={setValue}
                        value={formState.cc_requirement}
                    />
                </div>
                </>
        );
    }

    return (
        <div>
            <div className={Style.scrollWrap}>
                <div>
                    { renderInterruptibleReqForm() }
                </div>
                <div>
                    { renderChoiceCustomerReqForm() }
                </div>
                <div className={Style.totalWrap}>
                    <Alert
                        message={(<Text>Requirement Total: <strong>{ requirementTotal }</strong></Text>)}
                        type="info"
                    />
                </div>
                <Divider />
                <div>
                    <NumberInput 
                        label="Max Injection"
                        required
                        placeholder="0"
                        name="max_injection"
                        id="max_injection"
                        onChange={setValue}
                        value={formState.max_injection}
                    />
                </div>
                <div>
                    <NumberInput 
                        label="Max Withdrawal"
                        required
                        placeholder="0"
                        name="max_withdrawal"
                        id="max_withdrawal"
                        onChange={setValue}
                        value={formState.max_withdrawal}
                    />
                </div>
            </div>
            
            <Grid cols="2">
                    {isValid() &&
                        <Button
                            fullWidth
                            loading={ loading }
                            text="Submit"
                            action={ submit }
                        />
                    }
                    <Button fullWidth outline text='Cancel' action={ () => context.modal.hide(true) } />
                </Grid>
        </div>
    );
}