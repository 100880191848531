/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext, useEffect } from 'react';
import { AccountContext, AuthContext, AppNav, Header, getNominationEntries, getTaskStatusData, Loader, User, SocketContext, ViewContext, useUpdateStorage } from 'components/lib';
import ClassNames from 'classnames';
import Style from './app.module.scss';
import '../layout.scss'; // globals

import moment from 'moment';

export function AppLayout(props){

  // context & style
  const context = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const socket = useContext(SocketContext);
  
  //useUpdateStorage();

  const { accountState, accountDispatch } = useContext(AccountContext);

  useEffect(() => {

    if (!accountState.loading) {
      if (!accountState.task_statuses) getTaskStatusData(accountDispatch);
      if (!accountState.nomination_entries) getNominationEntries({flow_date: moment().format('YYYY-MM-DD')}, accountDispatch);
    }

    if (accountState.error) {
      viewContext.handleError(accountState.error);
    }

  }, [accountState, accountDispatch]);

  useEffect(() => {
    console.log('SOCKET IN EFFECT', socket.connected);

    if (!socket.connected) {
      socket.on('ADD_NOMINATION_ENTRY', (payload) => {


          console.log('ADD_NOMINATION_ENTRY - ', payload);

          // check entry to see if its of todays date then add to state
          accountDispatch({
            type: 'ADD_NOMINATION_ENTRY',
            payload
          });
      });

      socket.on('DELETE_NOMINATION_ENTRY', (payload) => {
        console.log('DELETE_NOMINATION_ENTRY - ', payload);

        accountDispatch({
          type: 'DELETE_NOMINATION_ENTRY',
          payload
        });
      });

      socket.on('UPDATE_STATUS_NOMINATION_ENTRY', (payload) => {
        console.log('UPDATE_STATUS_NOMINATION_ENTRY - ', payload);

        accountDispatch({
          type: 'UPDATE_NOMINATION_TASK_STATUS',
          payload
        });
      });

    }

  }, []);

  const css = ClassNames([ Style.app, 'with-sidebar' ]);

  // call account actions to get data and store it to state tree

  const hasData = () => accountState.task_statuses;

  const Styles = {
    'button': {
      backgroundColor: 'black !important'
    } 
  }

  return (
    <Fragment>
      <AppNav
        type='popup'
        items={[
          { label: 'Nominations', icon: 'activity', link: '/nomination-board' },
          { label: 'Interruptibles', icon: 'activity', link: '/interruptible-board' },
          { label: 'Utility Reqs.', icon: 'activity', link: '/utility-requirements' },
          { label: 'Nom Chart', icon: 'activity', link: '/nomination-chart' },
          //{ label: 'Help', icon: 'help-circle', link: '/help' },
          //{ label: 'Account', icon: 'user', link: '/account' },
          { label: 'Sign Out', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={ css } style={Styles}>
        <Header title={ props.title }>
          <User/>
        </Header>

        {hasData() ? 
          <props.children {...props.data }/> : 
          <Loader fullScreen />   
        }

      </main>
    </Fragment>
  );
}
