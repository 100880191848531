import Axios from "axios";

export const setLoading = (dispatch, status) => {
    dispatch({
        type: "SET_LOADING",
        payload: status
    });
}

export const getTaskStatusData = async (dispatch) => {
    setLoading(dispatch, true);

    try {
        const res = await Axios.get('/api/task-status');

        if (res.status === 200) {
            dispatch({
                type: "SET_TASK_STATUS",
                payload: res.data.data
            });
            setLoading(dispatch, false);
        }
    } catch (e) {
        dispatch({
            type: "SET_ERROR",
            payload: e
        });
    }
}

export const getNominationEntries = async (data, dispatch) => {
    setLoading(dispatch, true);

    try {
        const res = await Axios.get('/api/daily-nomination/nomination-entries', {
            params: { 
                flow_date: data.flow_date 
            }
        });

        if (res.status === 200) {
            dispatch({
                type: "SET_NOMINATION_ENTRIES",
                payload: res.data.data
            });
            setLoading(dispatch, false);
        }
    } catch (e) {
        dispatch({
            type: "SET_ERROR",
            payload: e
        });
    }
}