import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Axios from 'axios';
import { AccountContext, Animate, Card, Grid, Select, ViewContext } from 'components/lib';
import { currency } from 'utilities/currency';

import { Button as AntButton, Alert, Col, Input, List, Row, Space, Table, Tooltip, Typography, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Highlighter from 'react-highlight-words';

import moment from 'moment';

import Style from './nomination-board.module.scss';

const { Paragraph, Text, Title } = Typography;

export function NominationEntry() {
    const context = useContext(ViewContext);
    const { accountState, accountDispatch } = useContext(AccountContext);

    const history = useHistory();
    const location = useLocation();

    const [entry, setEntry] = useState(undefined);
    const [taskStatuses, setTaskStatuses] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getTaskStatusOptions = () => {
        const availOpts = entry.confirmationEmailSent ? taskStatuses : taskStatuses.filter(ts => ts.priority !== 2);

        return availOpts.map(ts => ({ label: ts.name, value: ts.id }))
    }

    useEffect(() => {
        if (!location?.state?.entry_id || !accountState?.nomination_entries) {
            history.push({
                pathname: '/nomination-board'
            });
        } else {
            if (!entry) {
                const selectedNomData = accountState.nomination_entries.find(nom => nom.id === location.state.entry_id)
                setEntry(selectedNomData);
            }
            if (!taskStatuses) setTaskStatuses(accountState.task_statuses);

        }

    }, [accountState]);

    const getNominationTaskStatus = () => taskStatuses.find(ts => ts.id === entry.taskStatusId);

    const sendNomEntryConfirmation = async () => {
        setLoading(true);
        try {
            const res = await Axios.post('/api/daily-nomination/nomination-entry-confirmation/email', {
                flow_date_id: entry.flowDateId,
                account_id: entry.accountId,
                id: entry.id
            });    
            
            if (res.status === 200) {
                setEntry({
                    ...entry,
                    confirmationEmailSent: 1
                })
                setLoading(false);
            }
        } catch (err){
            context.handleError(err);
            setLoading(false);
        }
    }

    const prepareSettingsTooltip = (obj) => {
        if (!obj) {
            return (
                <Tooltip 
                    color="#ffffff"
                    title={
                        <Space direction='vertical'>
                            <Text>Please contact support about missing credentials.</Text>
                        </Space>
                    }>
                    <AntButton className={Style.credentialBtn} danger>No Credentials</AntButton>
                </Tooltip>
            );
        }

        return (
            <Tooltip 
                color="#ffffff"
                title={
                    <Space direction='vertical'>
                        <Paragraph copyable={{text: obj.website_url }}>
                            <Text><strong>Website:</strong> <Text code>{obj.website_url}</Text></Text>
                        </Paragraph>
                        <Paragraph copyable={{text: obj.username}}>
                            <Text><strong>Username:</strong> <Text code>{obj.username}</Text></Text>
                        </Paragraph>
                        <Paragraph copyable={{text: obj.password}}>
                            <Text><strong>Password:</strong> <Text code>{obj.password}</Text></Text>
                        </Paragraph>
                    </Space>
                }>
                <AntButton className={Style.credentialBtn}>Show Credentials</AntButton>
            </Tooltip>
        );
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div
            style={{
              padding: 8,
            }}
          >
            <Input
              ref={searchInput}
              placeholder={`Search ${placeholder}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                marginBottom: 8,
                display: 'block',
              }}
            />
            <Space>
              <AntButton
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Search
              </AntButton>
              <AntButton
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Reset
              </AntButton>
              <AntButton
                type="link"
                size="small"
                onClick={() => {
                  confirm({
                    closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </AntButton>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#1890ff' : undefined,
            }}
          />
        ),
        onFilter: (value, record) =>
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
        render: (text) =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
              }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
    const getSpotDeals = () => {
        if (!entry?.spotDeals?.length) return null;

        const spotDeals = entry?.spotDeals;
        const columns = [
            {
                title: 'Spot Deal',
                render: (record, index) => {

                    return (
                        <Fragment>
                        Volume: {record.volume}
                        <br />
                        Base Price: {record.base_price}
                        <br />
                        Midpoint: {record.midpoint === null || record.midpoint === undefined ? 'N/A' : currency(record.midpoint)}
                        <br />
                        Final Price: {record.final_price === null || record.final_price === undefined ? 'N/A' : currency(record.final_price)}
                        <br />
                        Total: {record.final_price}
                        Pipeline: {record.pipeline}
                        <br />
                        Position: {record.position}
                        <br />
                        Location: {record.point}
                        <br />
                        Supplier: {record.company}
                    </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
              title: 'Volume',
              dataIndex: 'volume',
              key: 'volume',
              sorter: (a, b) => a.volume - b.volume,
              responsive: ["sm"]
            },
            {
                title: 'Base Price',
                dataIndex: 'base_price',
                key: 'base_price',
                render: (base_price) => `$${base_price}`,
                sorter: (a, b) => a.base_price - b.base_price,
                responsive: ["sm"]
            },
            {
                title: 'MidPoint',
                dataIndex: 'midpoint',
                key: 'midpoint',
                render: (midpoint, entry) => midpoint === null || midpoint === undefined || entry.fix_price !== null ? 'N/A' : currency(midpoint),
                sorter: (a, b) => a.midpoint - b.midpoint,
                responsive: ["sm"]
            },
            {
                title: 'Fix Price',
                dataIndex: 'fix_price',
                key: 'fix_price',
                render: (fix_price, entry) => {
                    const fixPriceData = entry.is_long_term ? entry.fix_price_slot : fix_price;
                    return fixPriceData ? currency(fixPriceData) : '$0.00';
                },
                sorter: (a, b) => a.fix_price - b.fix_price,
                responsive: ["sm"]
            },
            {
                title: 'Total',
                dataIndex: 'final_price',
                key: 'final_price',
                render: (final_price) => final_price === null || final_price === undefined ? 'N/A' : currency(final_price),
                sorter: (a, b) => a.final_price - b.final_price,
                responsive: ["sm"]
            },
            {
                title: 'Pipeline',
                dataIndex: 'pipeline',
                key: 'pipeline',
                sorter: (a, b) => a.pipeline.toLowerCase().localeCompare(b.pipeline.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Position',
                dataIndex: 'position',
                key: 'position',
                sorter: (a, b) => a.position.toLowerCase().localeCompare(b.position.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Location',
                dataIndex: 'point',
                key: 'point',
                sorter: (a, b) => a.point.toLowerCase().localeCompare(b.point.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Supplier',
                dataIndex: 'company',
                key: 'company',
                sorter: (a, b) => a.company.toLowerCase().localeCompare(b.company.toLowerCase()),
                responsive: ["sm"]
            }
          ];


        return (
            <Card title="Spot Deals">
                <Table 
                    columns={columns} 
                    dataSource={spotDeals} 
                />
            </Card>
        );
    }

    const getPipelineNoms = () => {

        if (!entry?.pipelineNoms?.length) return null;


        const getColumns = (contractAmount) => ([
            {
                title: 'Pipeline Nomination',
                render: (record, index) => {
                    return (
                        <Fragment>
                            Volume: {record.volume}
                            <br />
                            Contract: {record.contract_num}
                            <br />
                            Location: {record.location_num}
                            <br />
                            Package Id: {record.package_id}
                            <br />
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
                title: 'Volume',
                dataIndex: 'volume',
                key: 'volume',
                responsive: ["sm"]
            },
            {
                title: 'Contract',
                dataIndex: 'contract_num',
                key: 'contract_num',
                responsive: ["sm"],
                ...contractAmount > 1 ? getColumnSearchProps('contract_num', 'Contract #') : null
            },
            {
                title: 'Location',
                dataIndex: 'location_num',
                key: 'location_num',
                responsive: ["sm"]
            }
        ]);

        const currentTaskStatusObj = getNominationTaskStatus();      

        const renderPipelineTables = () => {

            const pipelineBatch = entry.pipelineNoms.reduce((obj, pl) => {
                if (!obj[pl.pipeline_id]) obj[pl.pipeline_id] = [];
    
                obj[pl.pipeline_id].push(pl);
                return obj;
            }, {});

            const pipelineIds = Object.keys(pipelineBatch);

            return pipelineIds.map(plId => {
                const plArr = pipelineBatch[plId];
                const pipelineHeader = plArr[0]['pipeline_name'];
                const siteSettings = plArr[0]['site_settings'];

                return (
                    <div className={Style.tableWrap}>
                        <Table
                                columns={getColumns(plArr.length)}
                                dataSource={plArr}
                                hideOnSinglePage
                                pagination={false}
                                bordered
                                title={() => (
                                    <Space className={Style.tableHeader} direction='horizontal'>
                                        <Text>{pipelineHeader}</Text>
                                        {prepareSettingsTooltip(siteSettings)}
                                    </Space>
                                    
                                )}
                            >
                        </Table>
                    </div>
                );
            });

        }


        return (
            <>
                    <Card title={`Flow Date: ${moment.utc(entry.flowDate).format('YYYY-MM-DD')}`}>
                        <Grid cols="2">
                                <div>
                                    <Select
                                        name="task_statuses"
                                        value={entry.taskStatusId}
                                        default={entry.taskStatusId}
                                        onChange={async (id, value) => {
                                            await updateNominationStatus(value);
                                        }}
                                        options={getTaskStatusOptions()}
                                    >
                                    </Select>
                                </div>
                                <div>
                                    {currentTaskStatusObj?.priority > 0 &&
                                        <AntButton 
                                            size="large"
                                            disabled={entry.confirmationEmailSent}
                                            onClick={async () => { await sendNomEntryConfirmation() }}
                                            loading={loading}
                                        >
                                            {!entry.confirmationEmailSent ? 'Send Confirmation Email' : 'Confirmation Email Sent'}
                                        </AntButton>
                                    }
                                    
                                </div>
                        </Grid>
                        <Space direction='vertical'>
                            {entry?.spotDealCount > 0 &&
                                <div>
                                    <Alert
                                        message={
                                            <>
                                                <p>This nomination has spot deals.</p>
                                            </> 
                                        }
                                        type="warning"
                                    />
                                </div>
                            }
                            {!!entry.pendingFlowDate && 
                                <div>
                                    <Alert
                                        message={
                                            <>
                                                <p>This nomination has only been saved and has not been finalized by the scheduler.</p>
                                                <p>Scheduler may make changes to the data before completing nomination.</p>
                                            </> 
                                        }
                                        type="warning"
                                    />
                                </div>
                            }
                            {entry.confirmationEmailSent === 0 &&
                                <div>
                                    <Alert
                                        message={<p>Remember to submit confirmation email before moving task to DONE.</p>}
                                        type="info"
                                    />
                                </div>
                            }
                        </Space>
                        
                    </Card>
                    <Card
                        title="Pipeline Nomination"
                        className={Style.pipeLineSection}
                    >   
                        { renderPipelineTables() }
                    </Card>
            </>
        );
    }

    const getContracts = (pipelineRecords) => {
        
        const columns = [
            {
                title: 'Contracts',
                render: (record, index) => {
                    return (
                        <Fragment>
                            Contract: {record.contract_num}
                            <br />
                            Location: {record.location_num}
                            <br />
                            Volume: {record.volume}
                            <br />
                            Pipeline: {record.pipeline_name}
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
                title: 'Contract',
                dataIndex: 'contract_num',
                key: 'contract_num',
                responsive: ["sm"],
                ...pipelineRecords.length > 1 ? getColumnSearchProps('contract_num', 'Contract #') : null
            },
            {
                title: 'Location',
                dataIndex: 'location_num',
                key: 'location_num',
                responsive: ["sm"]
            },
            {
                title: 'Volume',
                dataIndex: 'volume',
                key: 'volume',
                responsive: ["sm"]
            },
            {
                title: 'Pipeline',
                dataIndex: 'pipeline_name',
                key: 'pipeline_name',
                responsive: ["sm"]
            }
        ];

        return (
            <div className={Style.tableWrap}>
            <Table
                                        title={() => (<Title level={4}>Contracts</Title>)}
                                        columns={columns}
                                        dataSource={pipelineRecords}
                                        hideOnSinglePage
                                        pagination={false}
                                        bordered
                                    >
                                    </Table>
            </div>
        );
    }

    const getUtilityNoms = () => {

        if (!entry?.utilityNoms?.length) return null;

        const renderUtilityNoms = (item) => {

            const columns = [
                {
                    title: 'Interruptibles',
                    render: (record, index) => {
                        return (
                            <Fragment>
                                Utility: {record.utility_name}
                                <br />
                                Pipeline: {record.pipeline_name}
                                <br />
                                Name: {record.interruptible_name}
                                <br />
                                Dths Flow: {record.dths_flow}
                                <br />
                                Actual Flow: {record.actual_flow}
                            </Fragment>
                        );
                    },
                    responsive: ["xs"]
                },
                {
                    title: 'Utility',
                    dataIndex: 'utility_name',
                    key: 'utility_name',
                    responsive: ["sm"]
                },
                {
                    title: 'Pipeline',
                    dataIndex: 'pipeline_name',
                    key: 'pipeline_name',
                    responsive: ["sm"]
                },
                {
                    title: 'Name',
                    dataIndex: 'interruptible_name',
                    key: 'interruptible_name',
                    responsive: ["sm"]
                },
                {
                    title: 'Dths Flow',
                    dataIndex: 'dths_flow',
                    key: 'dths_flow',
                    responsive: ["sm"]
                },
                {
                    title: 'Actual Flow',
                    dataIndex: 'actual_flow',
                    key: 'actual_flow',
                    responsive: ["sm"]
                }
            ];

            return (
                <List.Item>
                    <List.Item.Meta
                        title={
                            <div className={Style.utilityInfoSection}>
                                <Row>
                                <Col lg={20} xs={24}>
                                    <Space 
                                        direction="horizontal" 
                                        size="large"
                                    >
                                        <Text><strong>Utility:</strong> {item.utility_name}</Text>
                                        <Text><strong>Volume:</strong> {item.volume}</Text>
                                    </Space>
                                </Col> 
                                <Col lg={4} xs={24}>
                                    {prepareSettingsTooltip(item?.site_settings)}
                                </Col>
                                </Row>
                            </div>
                        }
                        description={
                            <>
                                {item.contracts.length > 0 &&
                                    getContracts(item.contracts)
                                }
                                {item.interruptibles.length > 0 &&
                                    <div className={Style.subTableWrap}>
                                    
                                        <Table
                                            title={() => (<Title level={4}>Interruptibles</Title>)}
                                            columns={columns}
                                            dataSource={item.interruptibles}
                                            hideOnSinglePage
                                            pagination={false}
                                            bordered
                                        >
                                        </Table>
                                
                                    </div>
                                }
                            </>
                        }
                    >

                    </List.Item.Meta>
                </List.Item>
            );
        }

        return(
            <Card
                        title="Utility Nomination"
                        className={Style.pipeLineSection}
                    >   
                        <List 
                            size="small"
                            bordered
                            dataSource={entry.utilityNoms}
                            renderItem={renderUtilityNoms}
                        />
                        
                    </Card>
        );
    }

    const updateNominationStatus = async (id) => {
        try {
            const res = await Axios.patch('/api/task-status', {
                id: entry.id,
                task_status_id: id,
                task_type: 'nomination'
            });   
            
            if (res.status === 200) {
                accountDispatch({
                    type: 'UPDATE_NOMINATION_TASK_STATUS',
                    payload: {
                        task_status_id: res.data.data.task_status_id,
                        id: entry.id
                    }
                });
            }
        } catch (err){
            context.handleError(err);
        }
    }

    return(
        <Animate type="pop">
            { getPipelineNoms() }
            { getUtilityNoms() }
            { getSpotDeals() }
        </Animate>
    );
}