import { NominationBoard } from 'views/nomination-board/nomination-board';
import { InterruptibleBoard } from 'views/interruptible-board/interruptible-board';
import { NominationEntry } from 'views/nomination-board/nomination-entry';
import { UtilityRequirements } from 'views/utility-requirements/utility-requirements';
import { NominationChart } from 'views/nomination-chart/nomination-chart';

const Routes = [
  {
    path: '/nomination-board',
    view: NominationBoard,
    layout: 'app',
    permission: 'user',
    title: 'Nominations'
  },
  {
    path: '/interruptible-board',
    view: InterruptibleBoard,
    layout: 'app',
    permission: 'user',
    title: 'Interruptibles'
  },
  {
    path: '/nomination-entry',
    view: NominationEntry,
    layout: 'app',
    permission: 'user',
    title: 'Nomination Entry'
  },
  {
    path: '/utility-requirements',
    view: UtilityRequirements,
    layout: 'app',
    permission: 'user',
    title: 'Utility Reqs.'
  },
  {
    path: '/nomination-chart',
    view: NominationChart,
    layout: 'app',
    permission: 'user',
    title: 'Nomination Chart'
  }
]

export default Routes;
