import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { Animate, Card, Loader, Select, ViewContext, useAPI } from 'components/lib';
import moment from 'moment';

import { Button, Badge, Col, DatePicker, Divider, Row, Space, Table, Typography } from 'antd';

import { UtilityNomEmailSetup } from './utility-nom-email-setup';

const { Text } = Typography;

export function NominationChart() {
    const accounts = useAPI('/api/accounts');

    const context = useContext(ViewContext);

    const [selectedAccount, setSelectedAccount] = useState(undefined);
    const [selectedDate, setSelectedDate] = useState(undefined);
    const [selectedFlowDate, setSelectedFlowDate] = useState(undefined);
    const [utilities, setUtilities] = useState(undefined);
    const [cycles, setCycles] = useState([]);
    const [selectedCycle, setSelectedCycle] = useState(undefined);
    const [utilityReqs, setUtilityReqs] = useState([]);
    const [dailyNomination, setDailyNomination] = useState(undefined);
    const [utilityData, setUtilityData] = useState(undefined);

    const [loading, setLoading] = useState(false);
    const [loadingNomData, setLoadingNomData] = useState(false);

    const getAccountOptions = (obj) => {
        return obj
            .map(obj => ({
                label: obj.company_name,
                value: obj.id
            })
        );
    }

    const getCycleOptions = (cycles) => {
        return [...cycles
            .map(c => ({
                label: c.name,
                value: c.id
            })),
            ...[{
                label: "All",
                value: "unselected"
            }]];
    }

    useEffect(() => {

        setLoadingNomData(true);

        const getFlowDate = async () => {
            try {
                const res = await Axios.get('/api/flow-date/data-entry', {
                    params: { 
                        date: selectedDate.format('YYYY-MM-DD'),
                        account_id: selectedAccount.id
                    }
                });

                if(res.status === 200) {
                     setSelectedFlowDate(res.data.data);
                     setLoadingNomData(false);
                }
            } catch (err) {
                setLoadingNomData(false);
                context.handleError(err);
            }
        }

        if (selectedDate) {
            setUtilityData(undefined);
            setUtilityReqs([]);
            setDailyNomination(undefined);
            getFlowDate();
        }

    }, [selectedDate]);

    useEffect(() => {

        const getCycles = async () => {
            try {    
                const res = await Axios.get('/api/contract/cycles/data-entry');

                if (res.status === 200) {
                    setCycles(res.data.data);
                }
            } catch(err) {
                context.handleError(err);
            } 
        }

        if (!cycles.length) {
            getCycles();
        }
        
    }, []);
    
    useEffect(() => {

        const getUtilityReqs = async () => {
            try {
                setLoading(true);
                const utilityIds = utilities.map(util => util.id);

                const res = await Axios.get('/api/company-utility-requirements/data-entry', { params: { 
                    date: selectedDate.format('YYYY-MM-DD'),
                    utility_ids: utilityIds,
                    account_id: selectedAccount.id,
                    latest: 1
                }});

                if (res.status === 200) {
                    setUtilityReqs(res.data.data);
                    setLoading(false);
                    
                } 
            }
            catch(err) {
                context.handleError(err);
                setLoading(false);
            }
        }

        if(selectedAccount && utilities?.length) {
            getUtilityReqs();
        }

    }, [utilities, selectedDate]);

    useEffect(() => {
        const getUtilities = async () => {
            setLoading(true);
        
            try {
                const res = await Axios.get('/api/company-utility/data-entry', {
                    params: {
                        account_id: selectedAccount.id,
                        with_settings: 1
                    }
                });
        
                if (res.status === 200) {
                    setUtilities(res.data.data);
                    setLoading(false);
                }
            } catch (err) {
                context.handleError(err);
                setLoading(false);
            }
        }

        if (selectedAccount) {
            getUtilities();
        }

    }, [selectedAccount]);

    useEffect(() => {

        // get daily nominations
        const getDailyNominations = async () => {
            setLoadingNomData(true);

            try {
                const res = await Axios.get('/api/daily-nomination/data-entry', {  
                    params: { 
                        flow_date_id: selectedFlowDate.id,
                        account_id: selectedAccount.id,
                    } 
                });

                if (res.status === 200) {
                    if(res.data.data && res.data.data.length) {
                        setDailyNomination(res.data.data);
                    } 

                    setLoadingNomData(false);
                }          
              }
              catch (err){
                context.handleError(err);
                setLoadingNomData(false);
              }
        };

        if(selectedFlowDate && selectedAccount) {
            getDailyNominations();
        }

    }, [selectedFlowDate, selectedAccount]);

    useEffect(() => {

        if (Array.isArray(dailyNomination) && dailyNomination.length) {
            const utilityData = utilityReqs.map(utilReq => {
                const nomData = dailyNomination.find(nom => nom.utility_id === utilReq.utility_id);
                
                const plBatch = nomData.pipelineRecords.reduce((batch, pl) => {
                    if (!batch[pl.pipeline_id]) {
                        batch[pl.pipeline_id] = { 
                            quantity: pl.quantity, 
                            name: pl.name, 
                            cycles: pl.cycles,
                            receipt_location_name: pl.receipt_location_name,
                            delivery_location: pl.location
                        };
                    } else {    
                        const currentQuantity = batch[pl.pipeline_id]['quantity'];
                        batch[pl.pipeline_id]['quantity'] = currentQuantity + pl.quantity;
                    }
    
                    return batch;
                }, {});

                const keys = Object.keys(plBatch);
                const total = selectedCycle ? 
                    keys.reduce((sum, key) => {
                        const cycle = plBatch[key].cycles.find(obj => obj.cycle_id === selectedCycle.value);

                        return cycle ? cycle.quantity + sum : sum;
                    }, 0)
                    : keys.reduce((sum, key) => plBatch[key].quantity + sum, 0);
    
                return {...utilReq, pipelineRecords: plBatch, plTotal: total};
            });

            setUtilityData(utilityData);
        }

    }, [dailyNomination]);

    const prepareUtilityChartData = () => {
        const listPLRecords = (utilReqs) => {
            const plRecords = utilReqs.pipelineRecords;
            const keys = Object.keys(plRecords);
            
            const displayCycleQuantity = (pl) => {
                const cycleObj =  pl.cycles.find(obj => obj.cycle_id === selectedCycle.value);

                return cycleObj?.quantity || 0; 
            }

            const els = keys.map((key, index) => (
                <Row>
                    <Col span={10}>{plRecords[key].name}</Col>
                    <Col span={8}>{selectedCycle ? displayCycleQuantity(plRecords[key]) : plRecords[key].quantity}</Col>
                    {!index && <Col span={6}>{utilReqs.requirement}</Col>}
                </Row>
            ));

            return (
                <>
                    {els}
                    <Row>
                        <Col span={10}><Text strong>Total</Text></Col>
                        <Col span={8}>{utilReqs.plTotal}</Col>
                    </Row>
                </>
            );
        };

        const listInterruptibles = (intBatch, cc) => {
            const total = intBatch.reduce((sum, obj) => !isNaN(obj.dths_flow) ? obj.dths_flow + sum : sum, 0);
            const els = intBatch.map(intObj => (
                <Row>
                    <Col span={10}>{intObj.name}</Col>
                    {!isNaN(intObj.dths_flow) && <Col span={8}>{intObj.dths_flow}</Col>}
                    {isNaN(intObj.dths_flow) && <Col span={8}></Col>}
                    <Col span={6}>{intObj.requirement}</Col>
                </Row>
            ));

            return (
                <>
                    {els}
                    {!isNaN(cc) &&
                        <Row>
                            <Col span={10}>Choice Customer</Col>
                            <Col span={8}></Col>
                            <Col span={6}>{cc}</Col>
                        </Row>
                            
                    }
                    <Row>
                        <Col span={10}><Text strong>Total</Text></Col>
                        <Col span={8}></Col>
                        <Col span={6}>{total}</Col>
                    </Row>
                </>
            );
        };

        return utilityData.map(ud => {
            
            return (<Card>
                <Row>
                    <Col span={18}>
                        <Row>
                            <Col span={10}><Text strong>{ud.name}</Text></Col>
                            <Col span={8}>
                                <Space direction="horizontal" style={{ marginRight: '10px' }}>
                                    <Text><strong>Max Injection:</strong></Text>
                                    <Text>
                                        <Badge
                                            className="site-badge-count-109"
                                            count={ud.max_injection}
                                            showZero
                                            overflowCount={1000000}
                                            color='#faad14'
                                        />
                                    </Text>
                                </Space>
                                <Space direction="horizontal">
                                    <Text><strong>Max Withdrawal:</strong></Text>
                                    <Text>
                                        <Badge
                                            className="site-badge-count-109"
                                            count={ud.max_withdrawal}
                                            showZero
                                            overflowCount={1000000}
                                            color='#faad14'
                                        />
                                    </Text>
                                </Space>
                            </Col>
                            <Col span={6}><Text strong>Requirements</Text></Col>
                        </Row>

                        <Divider/>

                        <Row>
                            <Col span={10}><Text strong>Pipelines</Text></Col>
                            <Col span={14}>
                                <Text strong>
                                    {selectedCycle ? selectedCycle.label : "All"}
                                </Text>
                            </Col>
                        </Row>

                        {listPLRecords(ud)}

                        <Divider /> 

                        <Row>
                            <Col span={10}><Text strong>Interruptibles</Text></Col>
                            <Col span={14}></Col>
                        </Row>

                        {listInterruptibles(ud.interruptible_requirements, ud.cc_requirement)}

                        
                    </Col>                
                </Row>

                <Divider />

                {prepareMatrix(ud)}
                
            </Card>);


        });

    }

    const prepareMatrix = (ud) => {
        const displayPLDeliveries = () => {
            const columns = [
                {
                    title: 'Pipeline',
                    dataIndex: 'pipeline',
                    key: 'pipeline',
                    responsive: ["sm"]
                },
                {
                    title: 'Direction',
                    dataIndex: 'direction',
                    key: 'direction',
                    responsive: ["sm"]
                },
                {
                    title: 'Location',
                    dataIndex: 'location',
                    key: 'location',
                    responsive: ["sm"]
                },
                {
                    title: 'Volume',
                    dataIndex: 'volume',
                    key: 'volume',
                    responsive: ["sm"]
                },
                {
                    title: 'Volume By %',
                    dataIndex: 'volume_percent',
                    key: 'volume_percent',
                    responsive: ["sm"]
                },
            ];

            const plRecords = ud.pipelineRecords;
            const plIds = Object.keys(plRecords);

            const tableData = plIds.map(key => ({
                        pipeline: plRecords[key].name,
                        direction: `Receipt ${plRecords[key].receipt_location_name} - Delivered to ${plRecords[key].delivery_location}`,
                        location: `Volume at ${plRecords[key].delivery_location}`,
                        volume: `${plRecords[key].quantity} DTHS`,
                        volume_percent: `${parseFloat((plRecords[key].quantity / ud.plTotal) * 100).toFixed(2)} %`
                    }));
            
            const storageAmount = ud.plTotal - ud.requirement;
            const noStorage = storageAmount === 0;
            const isInjection = storageAmount > 0; 


            return (
                <Table
                columns={columns}
                dataSource={tableData}
                pagination={false}
                bordered
                
                summary={() => {

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4} align="right">
                                    <Text strong>Estimated Daily Delivery Volume:</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={1}>
                                    <Text>
                                        {ud.plTotal}
                                    </Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4} align="right">
                                    <Text strong>Estimated Customer Choice Daily Delivery Volume:</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={1}>
                                    <Text>
                                        {ud.cc_requirement}
                                    </Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4} align="right">
                                    <Text strong>Estimated Interuptible Daily Delivery Volume:</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={1}>
                                    <Text>
                                        {ud.interruptible_requirements.reduce((sum, obj) => sum + obj.requirement, 0)}
                                    </Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4} align="right">
                                    <Text strong>{`${ud.name} Total Requirements`}:</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={1}>
                                    <Text>
                                        {ud.requirement}
                                    </Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                            {!noStorage &&
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={4} align="right">
                                        <Text strong>{`${ud.name} ${isInjection ? 'Injection' : 'Withdrawal'} Amount`}:</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={1} colSpan={1}>
                                        <Text>
                                            {Math.abs(storageAmount)}
                                        </Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            } 

                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4} align="right">
                                    <Text strong>{`Total Deliveries to ${ud.name}`}:</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={1}>
                                    <Text>
                                        {ud.plTotal}
                                    </Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
                >
                </Table>
            );
        };

        return (
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                <Text strong>Nomination for Gas Daily {selectedDate.format('YYYY-MMM-DD')}</Text>
                            </Col>
                            <Col span={24}>
                                {displayPLDeliveries()}
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>
        );

    }

    const openEmailModal = () => {
        context.modal.show({
            title: 'Email',
            customForm: UtilityNomEmailSetup,
            formProps: { 
                flowDateId: selectedFlowDate.id,
                accountId: selectedAccount.id,
                untilityNomData: utilityData,
                selectedCycleId: selectedCycle?.value
            }
        });
    }

    return (
        <Animate type='pop'>
            <Card>
                <Row gutter={[8, 8]}>
                    {accounts.data?.length > 0 && 
                        <Col span={6}>
                        <Space direction='vertical' style={{ display: 'flex' }}>
                            <Text strong>Select An Account:</Text>
                                <Select
                                name="account"
                                value={selectedAccount}
                                onChange={(id, value) => {
                                    setSelectedAccount(value === 'unselected' ? undefined : accounts.data.find(obj => obj.id === value));
                                }}
                                options={getAccountOptions(accounts.data)}
                            >
                            </Select>
                        </Space>
                        
                        </Col>
                    }

                    {utilities?.length > 0 &&
                        <Col span={6}>
                            <Space direction='vertical' style={{ display: 'flex' }}>
                                <Text strong>Select A Daly Nomination Date:</Text>
                                <DatePicker 
                                    style={{ width: '100%' }}
                                    onChange={(dateObj) => setSelectedDate(dateObj)}
                                    direction="ltr"
                                    value={selectedDate}
                                />
                            </Space>
                        </Col>
                    }

                    {!loadingNomData && utilities?.length > 0 && Array.isArray(dailyNomination) && dailyNomination.length > 0  &&
                            <Col span={6}>
                                <Space direction='vertical' style={{ display: 'flex' }}>
                                    <Text strong>Select A Cycle:</Text>
                                    <Select
                                            name="cycle"
                                            value={selectedCycle}
                                            default={'unselected'}
                                            onChange={(id, value) => {
                                                setSelectedCycle(value === 'unselected' ? undefined : getCycleOptions(cycles).find(obj => obj.value === value));
                                            }}
                                            options={getCycleOptions(cycles)}
                                    >
                                    </Select>
                                </Space>
                            </Col>
                    }

                    {utilityData && 
                        <Col span={6}>
                            <Button 
                                style={{ width: '100%', height: '55px', marginTop: '30px' }}
                                size='large'
                                onClick={ e => openEmailModal() }
                            >
                                Email Report
                            </Button>
                        </Col>
                    }
                </Row>
            </Card>

            {loadingNomData && <Loader />}

            {!loadingNomData && Array.isArray(utilityData) && !dailyNomination.length &&
                <Card>
                    <p>This flow date does not have a nomimation created yet.</p>
                </Card>
            }

            {!loadingNomData && Array.isArray(utilityData) && utilityData.length > 0 && 
                prepareUtilityChartData()
            }
        </Animate>
    );
}