/***
*
*   SIGN UP STEP 2
*   Signup form for account owners
*   Step 1: create account
*   Step 2: select plan and make payment
*
**********/

import React, { useContext, useEffect, useState } from 'react';
import { Animate, AuthContext, Row, Card, PaymentForm, usePlans, Link } from 'components/lib';

import { Result } from 'antd';

export function SignupPlan(props){

  const context = useContext(AuthContext);
  const plans = usePlans();
  const plan = window.location.hash.substring(1);

  const [successResult, setSuccessResult] = useState(false);

  useEffect(() => {
    if(successResult) {
      setTimeout(() => {
        context.signout();
      }, 1000);
    }
  }, [context, successResult]);

  if (!plans.data)
    return false;

  return(
    <Animate type='pop'>
      <Row title='Select Your Plan'>
        {!successResult &&
          <Card loading={ false } restrictWidth center>

            <PaymentForm
              data={{
                plan: {
                  label: 'Plan',
                  type: 'select',
                  options: plans.data.list,
                  default: plan,
                  required: true,
                },
                token: {
                  label: 'Credit Card',
                  type: 'creditcard',
                  required: true,
                }
              }}
              url='/api/account/plan'
              method='POST'
              buttonText='Save Plan'
              callback={ res => {

                // save the plan to context, then redirect
                context.update({ plan: res.data.plan, subscription: res.data.subscription });
                //History.push(res.data.onboarded ? '/dashboard' : '/welcome');
                setSuccessResult(true);

              }}
            />

            <Link url='/account' text='Manage Your Account' />

          </Card>
        }
        {successResult &&
          <Card>

            <Result
              status="success"
              title="Account has been successfully created"
              subTitle="Your account will be activated shortly"
            />

          </Card>
        }
      </Row>
    </Animate>
  );
}
