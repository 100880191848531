import React, { createContext, useReducer } from 'react';
import AccountReducer from "./accountReducer";

const initialState = {
    task_statuses: undefined,
    nomination_entries: undefined,
    loading: false,
    error: undefined
}

export const AccountContext = createContext({ state: initialState });

export const AccountProvider = ({ children }) => {

    const [state, dispatch] = useReducer(AccountReducer, initialState);

    return (
        <AccountContext.Provider 
            value={{
               accountState: state,
               accountDispatch: dispatch 
            }}
        >
        {children}
        </AccountContext.Provider>
    );
}