import { Password } from 'views/account/password';

const Routes = [
  {
    path: '/account/password',
    view: Password,
    layout: 'app',
    permission: 'user',
    title: 'Your Password'
  }
]

export default Routes;