import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { Animate, Card, Grid, Select, ViewContext, useAPI } from 'components/lib';
import { UpdateUtilityReq } from './update-utility-req';
import moment from 'moment';

import { Alert, Button as AntButton, Collapse, DatePicker, Divider, List, Row, Col, Space, Tooltip, Typography } from 'antd';

import {
    EditOutlined
  } from '@ant-design/icons';

const { Panel } = Collapse;
const { Text } = Typography;

export function UtilityRequirements() {
    const accounts = useAPI('/api/accounts');

    console.log('accounts --> ', accounts);
    const context = useContext(ViewContext);

    const [selectedAccount, setSelectedAccount] = useState(undefined);
    const [selectedDate, setSelectedDate] = useState(moment());
    const [utilities, setUtilities] = useState(undefined);
    const [utilityReqs, setUtilityReqs] = useState([]);
    const [loading, setLoading] = useState(false);

    const getAccountOptions = (obj) => {
        return obj
            .map(obj => ({
                label: obj.company_name,
                value: obj.id
            }));
    }

    useEffect(() => {

        const getUtilityReqs = async () => {
            try {

                console.log('set loading 1');

                setLoading(true);
                const utilityIds = utilities.map(util => util.id);

                const res = await Axios.get('/api/company-utility-requirements/data-entry', { params: { 
                    date: selectedDate.format('YYYY-MM-DD'),
                    utility_ids: utilityIds,
                    account_id: selectedAccount.id,
                    latest: 1
                }});

                if (res.status === 200) {
                    setUtilityReqs(res.data.data);
                    setLoading(false);
                    
                } 
            }
            catch(err) {
                context.handleError(err);
                setLoading(false);
            }
        }

        if(selectedAccount && utilities?.length) {
            getUtilityReqs();
        }

    }, [utilities, selectedDate]);

    useEffect(() => {
        const getUtilities = async () => {

            console.log('set loading 2');

            setLoading(true);
        
            try {
                const res = await Axios.get('/api/company-utility/data-entry', {
                    params: {
                        account_id: selectedAccount.id,
                        with_settings: 1
                    }
                });
        
                if (res.status === 200) {
                    setUtilities(res.data.data);
                    setLoading(false);
                }
            } catch (err) {
                context.handleError(err);
                setLoading(false);
            }
        }

        if (selectedAccount) {
            getUtilities();
        }

    }, [selectedAccount]);

    const prepareListData = () => utilities.map(util => {
            const utilReq = utilityReqs.find(utilReq => utilReq.utility_id === util.id);
            util.req = utilReq ? utilReq : undefined;
            return util;
        });
    
    const openUpdateReqsModal = (utility) => {
        context.modal.show({
            title: 'Add Utility Requirements',
            customForm: UpdateUtilityReq,
            formProps: { 
                utility, 
                accountId: selectedAccount.id,
                values: {
                    ...utilityReqs.find(utilReqs => utilReqs.utility_id === utility.id),
                    date: selectedDate
                }
            }
        }, (form, res) => {
            const hasEntry = utilityReqs.find(utilReq => utilReq.utility_id === res.utility_id);
            
            if(hasEntry) {
                const newReqBatch = utilityReqs.map(utilReq => {
                    if(utilReq.utility_id === res.utility_id) return res;
                    return utilReq;
                });
                setUtilityReqs(newReqBatch);
            } else {
                utilityReqs.push(res);
                setUtilityReqs(utilityReqs);
            }            
        });
    }

    const renderRequirements = (itemObj) => {
        const hasInterruptibleReqs = itemObj.req.interruptible_requirements || itemObj.req.interruptible_requirements.length;

        return (
            <Collapse>
                <Panel 
                    key={1}
                    header={`Requiremnt: ${itemObj.req.requirement}`}
                >
                    <Row>
                        { hasInterruptibleReqs && itemObj.req.interruptible_requirements.map(obj => (
                            <>
                                <Col span={4}>
                                    <p><strong>{obj.name}</strong>:</p>
                                </Col>
                                <Col span={20}>{obj.requirement}</Col>
                            </>
                        )) 
                        }

                            <Col span={4}>
                                <p><strong>Choice Customer</strong>:</p>
                            </Col>
                            <Col span={20}>{itemObj.req.cc_requirement || 0}</Col>

                    </Row>

                </Panel>
        </Collapse>
        );
    }

    const renderUtilReqs = (item) => {
        const siteSettings = item?.settings;
        const prepareSettingsTooltip = (obj) => {
            if (!obj) {
                return (
                    <Tooltip 
                        color="#ffffff"
                        title={
                            <Space direction='vertical'>
                                <Text>Please contact support about missing credentials.</Text>
                            </Space>
                        }>
                        <AntButton danger>No Credentials</AntButton>
                    </Tooltip>
                );
            }

            return (
                <Tooltip 
                    color="#ffffff"
                    title={
                        <Space direction='vertical'>
                            <Text><strong>Website:</strong> <Text code>{obj.website_url}</Text></Text>
                            <Text><strong>Username:</strong> <Text code>{obj.username}</Text></Text>
                            <Text><strong>Password:</strong> <Text code>{obj.password}</Text></Text>
                        </Space>
                    }>
                    <AntButton>Show Credentials</AntButton>
                </Tooltip>
            );
        }
        const actionBtns = [
            <AntButton 
                    icon={<EditOutlined />} 
                    onClick={() => openUpdateReqsModal(item)} 
                />,
            prepareSettingsTooltip(siteSettings)
        ];

        return (
            <List.Item
                actions={actionBtns}
            >
                <List.Item.Meta 
                    title={(<Text strong>{item.name}</Text>)}
                    description={
                        <>
                            {!item.req &&
                                <Alert
                                    message="This utilities requirements need to be updated for today."
                                    type="warning"
                                />
                            }
                            {item.req &&
                                <ul>
                                    <li>
                                        { renderRequirements(item) }
                                    </li>
                                    <li>
                                        <Text>Max Withdrawal: {item.req.max_withdrawal}</Text>
                                    </li>
                                    <li>
                                        <Text>Max Injection: {item.req.max_injection}</Text>
                                    </li>
                                </ul>
                            }
                        </>
                    }
                />
            </List.Item>
        );
    }

    return (
        <Animate type='pop'>
            <Card 
                title="Utilities"
                loading={accounts.loading}
            >
                <Grid>
                    {accounts.data?.length > 0 && 
                        <>
                        <p>Select Account</p>
                        <Select
                            name="account"
                            value={selectedAccount}
                            onChange={(id, value) => {
                                setSelectedAccount(value === 'unselected' ? undefined : accounts.data.find(obj => obj.id === value));
                            }}
                            options={getAccountOptions(accounts.data)}
                        >
                        </Select>
                        </>
                    }
                </Grid>
                <Divider />
                 
                {utilities?.length > 0 &&
                    <>
                        <p>Select a date to display the utility requirements for that day.</p>
                        <DatePicker 
                            style={{ width: '100%' }}
                            onChange={(dateObj) => setSelectedDate(dateObj)}
                            direction="ltr"
                            value={selectedDate}
                        />
                        <Divider />
                        <List
                            size="small"
                            bordered
                            loading={loading}
                            dataSource={prepareListData()}
                            renderItem={renderUtilReqs}
                        />
                    </>
                }
                

            </Card>
        </Animate>
    );
}