import React, { Fragment, useContext, useEffect, useState } from 'react';

import { Animate, Card, ViewContext, useAPI } from 'components/lib';
import { AddActualFlow } from './add-actual-flow';

import moment from 'moment';
import { Button as AntButton, Divider, Space, Table, Typography } from 'antd';

export function InterruptibleBoard() {
    const context = useContext(ViewContext);
    const interruptibleEntries = useAPI('/api/interruptible/interruptible-entries');

    const [interruptibleEntryBatch, setInterruptibleEntryBatch] = useState(undefined);

    useEffect(() => {

        if (interruptibleEntries?.data?.length) setInterruptibleEntryBatch(interruptibleEntries.data);

    }, [interruptibleEntries]);

    const openFlowModal = (interruptible) => {
        context.modal.show({
            title: 'Add Actual Flow to Interruptible',
            customForm: AddActualFlow,
            formProps: { interruptible },
        }, (form, res) => {
            if (res) context.notification.show('Actual flow has been updated on interruptible.', 'success', true);

            const newBatch = interruptibleEntryBatch.filter(obj => obj.interruptible_entry_id !== interruptible.interruptible_entry_id);
            setInterruptibleEntryBatch(newBatch);
        });
    }

    const getInterruptibleEntries = () => {
        if (!interruptibleEntryBatch) return null;

        const columns = [
            {
                title: 'Interruptibles',
                render: (record, index) => {
                    return (
                        <Fragment>
                            Volume: {record.volume}
                            <br />
                            Contract: {record.contract_num}
                            <br />
                            Location: {record.location_num}
                            <br />
                            Package Id: {record.package_id}
                            <br />
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
                title: 'Name',
                dataIndex: 'interruptible_name',
                key: 'interruptible_name',
                responsive: ["sm"]
            },
            {
                title: 'Flow Date',
                dataIndex: 'flow_date',
                key: 'flow_date',
                responsive: ["sm"],
                render: (data) => moment.utc(data).format('YYYY-MM-DD'),
                sorter: (a, b) => moment.utc(a.flow_date) - moment.utc(b.flow_date),
            },
            {
                title: 'Utility',
                dataIndex: 'utility_name',
                key: 'utility_name',
                responsive: ["sm"]
            },
            {
                title: 'Pipeline',
                dataIndex: 'pipeline_name',
                key: 'pipeline_name',
                responsive: ["sm"]
            },
            {
                title: 'Action',
                key: 'action',
                width: 150,
                render: (record) => {
                    return (
                        <Space size="middle">
                            <AntButton onClick={() => openFlowModal(record)}>Provide Actual Flow</AntButton>
                        </Space>
                    );
                }
            }
        ];  
        
        return (
            <Table
                columns={columns}
                dataSource={interruptibleEntryBatch}
                hideOnSinglePage
                pagination={false}
                bordered
            >
            </Table>
        );
    
    }


    return (
        <Animate type="pop">
            <Card>
                <p>Interruptibles that are waiting for "Actual Flow" data.</p>
                <Divider />
                { getInterruptibleEntries() }
            </Card>
        </Animate>
    );
}