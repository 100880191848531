import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Axios from 'axios';

import { AccountContext, ViewContext } from 'components/lib';
import { Spin, Tag, Tooltip, Typography } from 'antd';

import Board, { addCard, removeCard, moveCard } from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css';
import Style from './nomination-board.module.scss';

const { Text } = Typography;

export function NominationBoard() {
    const { accountState, accountDispatch } = useContext(AccountContext);
    const context = useContext(ViewContext);
    const history = useHistory();

    const [nominations, setNominations] = useState(undefined);
    const [taskStatuses, setTaskStatuses] = useState(undefined);
    const [board, setBoard] = useState(undefined);
    const [loadingCardId, setLoadingCardId] = useState(undefined);

    const prepareColumns = useCallback(() => taskStatuses.map(taskStatus => {
        const column = {
            id: taskStatus.id,
            title: taskStatus.name,
            cards: []
        };

        if (nominations) column.cards = nominations.filter(nom => nom.taskStatusId === taskStatus.id).map(nom => ({ 
            id: nom.id, 
            title: nom.title, 
            description: nom.description,
            pendingFlowDate: nom.pendingFlowDate,
            currentAssignee: nom.currentAssignee 
        }));
        return column;
    }), [nominations, taskStatuses]);

    const getBoard = useCallback(() => {
        return {
            columns: prepareColumns()
        }
    }, [prepareColumns]);

    useEffect(() => {
        if (accountState.task_statuses) {
            accountState.task_statuses.sort((a, b) => a.priority - b.priority);
            setTaskStatuses(accountState.task_statuses);
        }


        if (accountState.nomination_entries !== undefined) {
            setNominations(accountState.nomination_entries);

            if (taskStatuses?.length) setBoard(getBoard());
        }


        if (nominations?.length) {

            if (nominations.length < accountState.nomination_entries.length) {

                const newNomEntry = accountState.nomination_entries.find(currentEntry => !nominations.find(nom => nom.id === currentEntry.id));

                if (newNomEntry){

                    const toDoTaskObj = taskStatuses.find(obj => !obj.priority);
                    const inColumn = { id: toDoTaskObj.id };

                    addCard(board, inColumn, newNomEntry, { on: 'top' });
                    setNominations([...nominations, newNomEntry]);
                }

            } else if (nominations.length > accountState.nomination_entries.length) {

                const cardToRemove = nominations.find(nom => !accountState.nomination_entries.find(ne => ne.id === nom.id));

                if (cardToRemove) {
                    const newNominations = nominations.filter(newNom => newNom.id !== cardToRemove.id);
                    const columnToRemoveCard = board.columns.find(column => column.cards.find(c => c.id === cardToRemove.id));

                    removeCard(board, columnToRemoveCard, cardToRemove);
                    setNominations([...newNominations]);
                }

            }
        }
  
    }, [accountState, getBoard]);

    useEffect(() => {

        if (taskStatuses?.length) {
            setBoard(getBoard());
        }

    }, [taskStatuses, nominations, getBoard]);

    const updateCardStatus = async (card, newColumn) => {
        setLoadingCardId(card.id);
        try {
            const res = await Axios.patch('/api/task-status', {
                id: card.id,
                task_status_id: newColumn.toColumnId,
                task_type: 'nomination'
            });    
            
            if (res.status === 200) {
                accountDispatch({
                    type: 'UPDATE_NOMINATION_TASK_STATUS',
                    payload: {
                        task_status_id: res.data.data.task_status_id,
                        id: card.id,
                        current_assignee: card.currentAssignee
                    }
                });

                setLoadingCardId(undefined);
            }
        } catch (err){
            context.handleError(err);
            setLoadingCardId(undefined);
        }
    }

    const sendToNominationEntry = async (entryId) => {
        if (loadingCardId === entryId) return false;
        const entry = nominations.find(nom => nom.id === entryId);

        history.push({
            pathname: '/nomination-entry',
            state: { entry_id: entry.id }
        });
    }

    return (
        <>
            {board && 
                <Spin spinning={nominations === undefined} delay={500}>
                <Board
                    onCardDragEnd={async (cardData, fromColumnData, toColumnData) => {  
                        const currentCardNomination = nominations.find(nom => nom.id);
                        const confirmationEmailSent = currentCardNomination.confirmationEmailSent;
                        const nextTaskStatus = taskStatuses.find(ts => ts.id === toColumnData.toColumnId);

                        if (nextTaskStatus.priority === 2) {
                            if (!confirmationEmailSent) {
                                context.notification.show('Confimation email must be sent before moving card to done.', 'error'); 
                                return;
                            }
                        }
                        

                        const newBoard = moveCard(board, fromColumnData, toColumnData);
                        const user = JSON.parse(localStorage.getItem('user'));

                        cardData.currentAssignee = nextTaskStatus.priority > 0 ? user.name : undefined;

                        setBoard(newBoard);
                        await updateCardStatus(cardData, toColumnData);
                        
                    }}
                    renderCard={(data) =>  (
                        <div 
                            className={Style.card}
                            onClick={() => sendToNominationEntry(data.id)}
                        >
                            <div className={Style.header}>
                                {data.title}
                            </div>
                            <div className={Style.description}>
                                {data.description}
                            </div>
                            
                            {!!data.pendingFlowDate &&
                                <div className={Style.pendingTag}>
                                    <Tooltip title="This nomination is currently saved and has not been finalized by the scheduler. Changes my occur to the data before its completion.">
                                    <Tag color="blue">
                                        PENDING    
                                    </Tag>
                                    </Tooltip>
                                </div>
                            }

                            {data.currentAssignee && 
                                <Text italic className={Style.assignee}>Assigned to: {data.currentAssignee}</Text>
                            }
                            
                        </div>
                      )}
                >
                    {board}
                </Board>
                </Spin>
            }   
        </>
    );
}