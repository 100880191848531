/***
*
*   ACCOUNT NAV
*   Acount sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners
*
**********/

import React from 'react';
import { NavLink } from "react-router-dom";
import './sub.scss';

export function AccountNav(props){

  return(
    <nav className='subnav'>

      <NavLink exact to='/account/password' activeClassName='active'>Password</NavLink>

    </nav>
  );
}
