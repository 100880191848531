import React, { useContext, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid, NumberInput } from 'components/lib';
import moment from 'moment';
import { Divider } from 'antd';

export function AddActualFlow({ interruptible }) {
    const context = useContext(ViewContext);

    const [loading, setLoading] = useState(false);
    const [actualFlow, setActualFlow] = useState(undefined);

    const submit = async () => {
        setLoading(true);

        try {
            const res = await Axios.post('/api/interruptible/actual-flow', {
                actual_flow: actualFlow,
                interruptible_dths_flow_id: interruptible.id,
                interruptible_entry_id: interruptible.interruptible_entry_id
            });
      
            if (res.status === 200) {
                setLoading(false);
                context.modal.hide(false, res.data);
            }          
          }
          catch (err){
            setLoading(false);
            context.handleError(err);
            context.modal.hide(true);
          }
    }

    return (
        <div>
            <p><strong>Flow Date:</strong> {moment.utc(interruptible.flow_date).format('YYYY-MM-DD')}</p>
            <Divider />
            <Grid>
                    <p>DTHS Flow: { interruptible.dths_flow }</p>
                    <NumberInput 
                            label="Actual DTHS Flow"
                            required
                            isFloat
                            placeholder="0"
                            name="actual_flow"
                            id="actual_flow"
                            onChange={(id, value) => setActualFlow(value)}
                            value={actualFlow}
                        />
                </Grid>
            <Grid>
                {actualFlow !== undefined &&
                    <Button
                        loading={ loading }
                        text="Submit"
                        action={ submit }
                    />
                }
                <Button outline text='Cancel' action={ () => context.modal.hide(true) } />
            </Grid>
            
            
        </div>
    );

}