const accountReducer = (state, action) => {
    switch (action.type) {
        case "SET_TASK_STATUS":
            return {
                ...state,
                task_statuses: action.payload
            }
        case "SET_NOMINATION_ENTRIES":
            return {
                ...state,
                nomination_entries: action.payload
            }
        case "ADD_NOMINATION_ENTRY":
            let currentEntries = state.nomination_entries;
            const newEntry = action.payload;

            if (Array.isArray(newEntry)) {
                currentEntries = [...currentEntries, ...newEntry];
            } else {
                // const duplicate = currentEntries.some(curEntry => curEntry.accountId === newEntry.accountId && curEntry.flowDateId === newEntry.flowDateId);
                // if (!duplicate) currentEntries.push(newEntry);

                currentEntries.push(newEntry);
            }

            return {
                ...state,
                nomination_entries: currentEntries
            }
        case "DELETE_NOMINATION_ENTRY":
            const oldEntry = action.payload;

            return {
                ...state,
                nomination_entries: state.nomination_entries.filter(entry => entry.id !== oldEntry.id)
            }
        case "UPDATE_NOMINATION_TASK_STATUS":
            const update_nomination_entries = state.nomination_entries.map(entry => {
                if(entry.id === action.payload.id) {
                    entry.taskStatusId = action.payload.task_status_id;
                    entry.currentAssignee = action.payload.current_assignee;
                }

                return entry;
            });

            return {
                ...state,
                nomination_entries: update_nomination_entries
            }
        case "SET_LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "SET_ERROR":
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}

export default accountReducer;