import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid, Radio, Select, useAPI, TextInput } from 'components/lib';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import Style from './utility-nom-email-setup.module.scss';

export function UtilityNomEmailSetup(props) {
    const context = useContext(ViewContext);

    const [loading, setLoading] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [content, setContent] = useState("");

    const submit = async () => {
        setLoading(true);
            try {
                const res = await Axios.post('/api/daily-nomination/nomination-utility-confirmation/email', { 
                    emails: recipients,
                    flow_date_id: props.flowDateId,
                    account_id: props.accountId,
                    utility_noms: props.untilityNomData,
                    cycle_id: props.selectedCycleId,
                    content
                });
          
                if (res.status === 200) {
                    setLoading(false);
                    
                    // send the user some where else
                    context.modal.hide(true);
                }          
            } catch (err){
                setLoading(false);
                context.handleError(err);
            }
    }

    return (
        <div className={Style.emailSetupFormWrap}>
            <Grid cols="1">
                    <div>
                        <ReactMultiEmail
                                className={Style.multiEmail}
                                placeholder="Add Recepient Email(s)"
                                emails={recipients}
                                onChange={(emails) => setRecipients(emails)}
                                validateEmail={email => {
                                    return isEmail(email); // return boolean
                                }}
                                getLabel={(email, index, removeEmail) => {
                                    return (
                                    <div data-tag key={index}>
                                        {email}
                                        <span data-tag-handle onClick={() => removeEmail(index)}>
                                        ×
                                        </span>
                                    </div>
                                    );
                                }}
                        />
                            <TextInput 
                                label="Email Body"
                                name="content"
                                id="content"
                                type="textarea"
                                onChange={(id, value) => setContent(value)}
                                value={content}
                            />
                    
                        <Grid cols="2">
                            {recipients.length > 0 &&
                                <Button
                                    fullWidth
                                    loading={ loading }
                                    text="Send Email"
                                    action={ submit }
                                />
                            }
                            <Button outline fullWidth text='Cancel' action={ () => context.modal.hide(true) } />
                        </Grid>
                    </div>
            </Grid>
        </div>
    );
}